const calculator = document.querySelector('.pricing-calculator .calculator');
if (calculator) {

    const slider = calculator.querySelector('#employeeSlider');
    const employeeCount = calculator.querySelector('#employeeCount');
    const totalCost = calculator.querySelector('#totalCost');
    const pricePerEmployee = parseFloat(calculator.dataset.pricePerEmployee);

    function updateCalculator() {
        const employees = slider.value;
        employeeCount.textContent = employees;
        totalCost.textContent = (employees * pricePerEmployee).toFixed(0);
    }

    slider.addEventListener('input', updateCalculator);
    updateCalculator(); // Initial calculation
}